
#topBox {
    
    /* top: 50%;
    left: 50%; */
    font-family: acier-bat-noir, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 4rem;   
}


#sunSet{
    height: 100vh;
    width: 100vw;
    position: fixed;
    justify-content: center;
    align-items: center;
}

#cardBox {
    position: absolute;
}
.parallax {
    overflow: hidden;
    letter-spacing: -2px;
    line-height: 0.8;
    margin: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
  }
  .parallax .scroller {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 64px;
    display: flex;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
  }
  .parallax span {
    display: block;
    margin-right: 30px;
  }
  .progress {
    position: fixed;
    left: 0;
    right: 0;
    height: 5px;
    background: var(--accent);
    bottom: 100px;
  }
  section {
    padding-top: 10vh;
    padding-bottom: 10vh;
    position: relative;
  }
  #jobs {
      height: 48vh;
      border: solid 13px;
      border-radius: 30px;
  }

#panel2{
    justify-content: space-evenly;
    padding-top: 10vh;
}

#panel2Body{
    font-size: 1.2em;
    font-family: "Century", Gothic;
}
#panel2Head{
    font-family: "Century", Gothic;
    font-weight: 900;
}
#bottomText{
    margin-top: 5em;
    font-family: chaparral-pro, serif;
    font-weight: 300;
    font-style: italic;
}
#journeyBox{
    padding-bottom: 2em;
}
#myJourney{
    font-size: 5rem;
    text-align: center;
    color: white;
}
#journey{
    padding-top: 10em;
    padding-bottom: 10em;
    flex-direction: column;
    font-family: chaparral-pro, serif;
    font-weight: 700;
    font-style: normal;
    backdrop-filter: blur(20px);
}
.cards{
    background: #333333;
    padding: 3rem 5rem;
    color: white;
    
    
}
.cards p {
    font-size: .6rem;
    color: darkgray;
}
#arrowSeperate{
    color: white;
    padding-top: 15vh;
    padding-bottom: 15vh;
    flex-direction: column;
    box-shadow: 0px 10px 30px rgb(0,0,0,.8);
}
#arrowSize{
    width: 4rem;
    height: 4rem;
}
li{
    margin-top: .2rem;
    margin-left: 5px;
}
dt {
    padding-right: 20px;
}
#moveLeft {
    margin-left: 1rem;
}
#otherWork{
    flex-direction: column;
    font-family: chaparral-pro, serif;
    font-weight: 700;
    font-style: normal;

}
#cardJob{
    background-color: rgba(0, 0, 0, 0.61);
    color: white;
    backdrop-filter: blur(50px);
}
#blueSteel{
    padding-top: 10em;
}
#modellHighlight{
    text-align: center;
    font-size: 2.2em;
    font-family: "Century", Gothic;
    font-style: italic;
    margin-bottom: 0;
    padding-bottom: 0;
}
#clickHere{
    text-align: center;
    font-family: "Century", Gothic;
    font-style: italic;
}
#campbelModelTitle{
    margin-bottom: 0;
    font-size: 5em;
    font-family: "Century", Gothic;
    font-style: italic;
}
#booking{
    font-family: "Century", Gothic;
    font-size: 1em;
    font-style: italic;
    text-align: center;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    color: #fff;
    background: linear-gradient(180deg, #4B91F7 0%, #367AF6 100%);
    background-origin: border-box;
    box-shadow: 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2);
    user-select: none;
  touch-action: manipulation;
}

.button-10:focus {
  box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2), 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), 0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
  outline: 0;
}


#infoTag{
    text-align: center;
    
}
#contentBox{
    display: flex;
    align-content:center;
    flex-direction: column;
    align-items: center;
}
#buttonBox{
    padding-top: 7.5rem;
}
#moreLinks{
    height: 15vh;
    background-color: rgba(0,0,0, .30);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 5em;
    font-family: "Century", Gothic;
    font-style: italic;

}
a {
    text-decoration: none;
    color:white;
}
.aboutBox{
    overflow: hidden;
}

td {
    font-size: 1.3rem;
}