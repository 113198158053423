.progress {
    position: fixed;
    left: 0;
    right: 0;
    height: 5px;
    background: var(--accent);
    bottom: 100px;
  }
 #slide img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    filter: drop-shadow(10px 10px 10px black);
  }
 #slide section > div {
    width: 300px;
    height: 400px;
    position: relative;
    max-height: 90vh;
    margin: 20px;
    background: var(--white);
    overflow: hidden;
  }
  #topSlide {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: chaparral-pro, serif;
    font-style: normal;
  }
#slide section {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    scroll-snap-align: center;
    perspective: 500px;
  }
  #givingBack{
    font-size: xx-large;
    font-weight: 700;
    text-align: center;
  }
  #donateCard{
      justify-content: center;
      padding-top:20rem;
      padding-bottom: 20rem;
  }
  #donateCard img{
      max-height: 100%;
      max-width: 100%;
  }
  .donateCards{
    background: #333333;
    padding: 1rem 1rem;
    color: white;
    display: flex;
    flex-direction: column;
}
.donateCards p{
    font-family: chaparral-pro, serif;
    font-style: normal;
    text-align: center;
}
.over{
  overflow:hidden;
}
#danBox:hover .image{
  opacity:0.2;
}
#danBox:hover .danHover{
  opacity: 1;
}

.danHover{
  color:white;
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  font-family: chaparral-pro, serif;
  font-weight: 700;
  font-style: normal;
}

#jpBox:hover .imageTwo{
  opacity:0.2;
}
#jpBox:hover .jpHover{
  opacity: 1;
}
.jpHover{
  color:white;
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  font-family: chaparral-pro, serif;
  font-weight: 700;
  font-style: normal;
}
#tigerBox:hover .imageThree{
  opacity:0.2;
}
#tigerBox:hover .tigerHover{
  opacity: 1;
}
.tigerHover{
  color:white;
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  font-family: chaparral-pro, serif;
  font-weight: 700;
  font-style: normal;
}
