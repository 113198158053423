
.background {
    place-items: center;
}
#start h1 {
    font-size: 5em;
    font-family: "Century", Gothic;
    font-style: italic;

}
h3 {
    font-size: 1.2em;
    font-family: "Century", Gothic;
    font-style: italic;

}
.centered {
    position: absolute;
    top: 48%;
    left: 16%;
    transform: translate( -50%, -50% );
    text-align: center;
    color: white;
    font-weight: bold;
}
.centeredd {
    position: absolute;
    top: 56%;
    left: 10.5%;
    transform: translate( -50%, -50% );
    text-align: center;
    color: white;
    font-weight: bold;
}
.centereddd {
    position: absolute;
    top: 60%;
    left: 10.5%;
    transform: translate( -50%, -50% );
    text-align: center;
    color: white;
    font-weight: bold;
}
.thumbnail {
    position: relative;
    display: inline-block;
}

br {
    margin-top: 25px;
}
#exploreButton {
    margin-left: 45%;
    
}
.icons{
    height: 1.5rem;
    width: 1.5rem;
}
#suns{
    width: 80%;
    height: 90%;
}
#exp {
    margin-right: 25px;
}
#abouts{
    height: max-content;
}
#roundabout{
    padding-top: 7em;
}
#sizingRound{
    height: 100vh;
}

.topPage{
    position: sticky;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}