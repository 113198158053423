.homeScroll {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}
.homeScroll >p{
    font-size: 5em;
    font-family: "Century", Gothic;
    font-style: italic;
    color: white;
    margin-bottom: 0;
}
.firstText {
    display: flex!important;
    font-family: "Century", Gothic;
    font-style: italic;
    flex-direction: column;
    color: white;
    padding-left:3%;
    font-size: 5rem;
}
.leaderTag h2{
    display: flex!important;
    font-family: "Century", Gothic;
    font-style: italic;
    flex-direction: column;
    color: white;
    font-size: 5.5rem;
}
.leaderTag{
    padding-left:2%;
    position: sticky;
}
.gentelmenTag h2{
    display: flex!important;
    font-family: "Century", Gothic;
    font-style: italic;
    flex-direction: column;
    color: white;
    padding-left:3%;
    font-size: 5.5rem;
    padding-top: 5rem;
}
.developerTag h2{
    display: flex!important;
    font-family: "Century", Gothic;
    font-style: italic;
    flex-direction: column;
    color: white;
    padding-left:4%;
    font-size: 5.5rem;
    padding-top: 9.5rem;
}
.homeButton {
    padding-top: 15rem;
    padding-left: 5%;
}
.homeButton h2{
    color: white;
    font-family: "Century", Gothic;
    font-style: italic;
    margin-left: 10px;
}
.homeButton button{
    background-color: #111827;
    border: 1px solid transparent;
    border-radius: .75rem;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    flex: 0 0 auto;
    font-family: "Inter var",ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.5rem;
    padding: .75rem 1.2rem;
    text-align: center;
    text-decoration: none #6B7280 solid;
    text-decoration-thickness: auto;
    transition-duration: .2s;
    transition-property: background-color,border-color,color,fill,stroke;
    transition-timing-function: cubic-bezier(.4, 0, 0.2, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 6em;
    margin-left: 10px;
}
